/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "@fontsource/lexend/200.css"
import "@fontsource/lexend/300.css"
import "@fontsource/lexend/600.css"
import "@fontsource/playfair-display/400.css"
import "@fontsource/montserrat/400.css"
import "./src/styles/styles.sass"
